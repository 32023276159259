<template>
	<div class="main1">
		<!-- <div v-for="(item,index) in memberList" :key="index">
			<invite-team :type="type" :codeObj="item" @createCode="makeInviteCode"></invite-team>
		</div> -->
		<invite-team :type="type" :codeObj="codeInfo" @createCode="makeInviteCode"></invite-team>
	</div>
</template>

<script>
	import InviteTeam from '@/components/content/InviteTeam.vue'
	import { groupData,addGroupCode } from "@/api/Mine"
	import { getUserInfo } from "@/utils/common/user";
	export default {
		props: {},
		data(){
			return {
				type: '1',//0填写邀请码 1邀请团队
				codeInfo: {
					code: ''
				},
				// memberList:[
				// 	{
				// 		code: '109384'
				// 	},
				// 	// {
				// 	// 	code: '109384'
				// 	// },
				// 	// {
				// 	// 	code: '109384'
				// 	// }
				// ],
				userInfo: {},//用户信息
			}
		},
		components:{
			InviteTeam
		},
		mounted() {
		  this.makeInviteCode();
		},
		created() {
			this.getTeamInfo();//团队信息
			// this.userInfo = JSON.parse(this.getUserInfo())
			// console.log(JSON.parse(this.getUserInfo()),'getUserInfo')
		},
		methods: {
			getUserInfo,
			// 团队信息
			getTeamInfo(){
				
				// this.userInfo = JSON.parse(this.getUserInfo())
				// console.log(this.userInfo,'this.userInfo')
				groupData({
					group_id: this.$store.state.groupId,
					group_type: this.$store.state.groupType
				}).then((res) => {
				      console.log(res,'团队信息成功');
					  // this.randCreatorList = res.data
					  // this.list = res.data.list
					  // this.numMusician = res.data.count
					  // console.log(res.data.count,'res.data.list.count')
				    })
				    .catch((err) => {
				      console.log(err,'团队信息失败');
				    });
			},
			
			// makeInviteCode(){
			// 	console.log('生成邀请码')
			// 	addGroupCode({}).then((res) => {
			// 	      console.log(res,'生成邀请码成功');
			// 		  this.codeInfo.code = res.data
			// 		  // this.randCreatorList = res.data
			// 		  // this.list = res.data.list
			// 		  // this.numMusician = res.data.count
			// 		  // console.log(res.data.count,'res.data.list.count')
			// 	    })
			// 	    .catch((err) => {
			// 	      console.log(err,'生成邀请码失败');
			// 	    });
			// },
			// 生成邀请码
			makeInviteCode() {
			  let params = {};
			  // params.group_id = localStorage.getItem("group_id");
			  params.group_id = this.$store.state.groupId
			  addGroupCode(params).then((res) => {
			    console.log(res,'makeInviteCode');
			    if (res.status == 1) {
			      this.codeInfo.code = res.data;
			    } else {
			      this.$message({
			        type: "error",
			        message: res.msg,
			      });
			    }
			  });
			},
			// toCreate(){
			// 	this.$router.push({
			// 		path: '/teamManagement/createTeamForm'
			// 	})
			// }
		}
	}
</script>

<style lang="less" scoped>
	.main1 {
		width: 100%;
	}
</style>