<template>
  <div class="musician">
    <div class="musician-content">
      <div class="musician-item">
        
        <div class="musician-intro">
          <div class="musician-intro-left">
            <p class="musician-intro-code">{{$t('exclusiveinvitationcode')}}</p>
            <p class="musician-intro-day">{{$t('invitationcodevalidityperiod')}}</p>
          </div>
          <!-- <div class="musician-intro-center">
            - - - - - -
          </div> -->
		  <div v-if="type == '0'" class="musician-intro-center-wrap">
			   <div class="musician-intro-center">
				   <!-- <el-input v-model="inviteCode" placeholder="_ _ _ _ _ _"></el-input> -->
				   <div class='am_payPwd' :id="`ids_${id}`">
				   			 <div class="am_payPwd_wrap">
				   				 <input type=""
				   				  autocomplete="new-password"  
				   				  maxlength="1"
				   				  @input="changeInput"
				   				  @click="changePwd"
				   				  v-model="pwdList[i]"
				   				  @keyup="keyUp($event)"
				   				  @keydown="oldPwdList = pwdList.length"
				   				  class="shortInput"
				   				  v-for="(v, i) in 6" :key="i">
				   				  <!-- <div style="width: 1px;height: 20px;background: red;">1</div> -->
				   			 </div>
				      
				   </div>
					<div class="right-button">
						<div @click="joinTeam" class="confirm">{{$t('confirm')}}</div>
					</div>
			   </div>
		  </div>
		  <div class="musician-intro-right">
			  <div v-if="type == '1'" class="100%">
			  			  <div v-if="!codeObj.code" class="musician-intro-center1">_ _ _ _ _ _</div>
			  			  <div v-if="codeObj.code" class="musician-intro-center1">{{ codeObj.code }}</div>
			  </div>
			  <div v-if="type == '1'" class="tolike">
			    <el-button class="create-code" type="primary" plain @click.stop="makeCode()"
			      >{{$t('generateinvitationcode')}}</el-button
			    >
			    <el-button
			      type="primary"
			  	class="copy-code"
			      v-clipboard:copy="codeObj.code"
			      v-clipboard:success="onCopy"
			      v-clipboard:error="onError"
			      >{{$t('copyinvitationcode')}}</el-button
			    >
			  </div>
		  </div>
		  
		 
        </div>
		
		
	  </div>
    </div>
  </div>
</template>

<script>
import { joinGroup,myInfo } from "@/api/Mine";
export default {
	props: {
		codeObj: {
			type: Object,
			default(){
				return {
					
				}
			}
		},
		type: {
			type: String,
			default: '1'
		}
	},
  data() {
    return {
      musicianList: [],
      remainList: [],
      arr: [],
      code: "",
	  inviteCode: '',
	  pwdList: [],
	  id: ''
    };
  },
  methods: {
	  getMyInfo(){
	  	myInfo({}).then((res) => {
	  	      console.log(res,'我的信息成功');
	  		  this.infoObj = res.data
	  		  this.$store.state.bankList = res.data.bankcard
	  		  this.$store.state.groupType = res.data.group_type
	  		  this.$store.state.isBoss = res.data.is_boss
	  		  this.$store.state.groupId = res.data.group_id
	  		  localStorage.setItem('myInfo', JSON.stringify(this.infoObj))
	  		  // console.log(this.$store.state.groupType,'userInfouserInfouserInfo222222222222')
	  		  console.log(this.infoObj,'JSON.stringify(this.infoObj)');
			  this.$router.push({
			  				  path: '/personalCenter/teamManagement'
			  })
	  		  // if(this.$store.state.groupType == 0){
	  		  // 	this.tabsList = this.tabsList1
	  		  // }else{
	  		  // 	this.tabsList = this.tabsList2
	  		  // }
	  	    })
	  	    .catch((err) => {
	  	      console.log(err,'我的信息失败');
	  	    });
	  },
	  // 加入团队
	  joinTeam(){
		  let params = {}
		  params.invite_code = this.inviteCode
		  joinGroup(params).then((res) => {
		    // console.log(res.data);
		    if (res.status == 1) {
		      this.$message({
		        type: "success",
		        message: this.$t("addsuccess"),
		      });
			  // this.$router.go(0)
			  this.getMyInfo();
			  
			 
			  
		    } else {
		      this.$message({
		        type: "error",
		        message: res.msg,
		      });
		    }
		  });
	  },
    onCopy() {
      this.$message({
        type: "success",
        message: this.$t("copyinvitationcode") + this.$t("success"),
      });
    },
    onError() {
      this.$message({
        type: "error",
        message: this.$t("recommend.copyCodeFailed"),
      });
    },

    makeCode() {
      let params = {};
	  this.$emit('createCode')
      // params.group_id = localStorage.getItem("group_id");
      // addGroupCode(params).then((res) => {
      //   // console.log(res.data);
      //   if (res.status == 1) {
      //     this.code = res.data;
      //   } else {
      //     this.$message({
      //       type: "error",
      //       message: res.msg,
      //     });
      //   }
      // });
    },
	//   支付框
	keyUp (ev) {
	  let index = this.pwdList.length
	  if (!index) return
	  if (ev.keyCode === 8) {
	    this.isDelete = true
	    if (this.oldPwdList === this.pwdList.length) {
	      if (index === this.pwdList.length) {
	        this.pwdList.pop()
	      }
	      index--
	    } else {
	      index > 0 && index--
	    }
	    // this.ipt[index].focus()
	  } else if (this.isDelete && index === this.pwdList.length && /^\d$/.test(ev.key)) {
	    this.isDelete = false
	    this.pwdList.pop()
	    this.pwdList.push(ev.key)
	    // this.ipt[this.pwdList.length] && this.ipt[this.pwdList.length].focus()
	  }
	  // this.$emit('getPwd', this.pwdList.join(''))
	  console.log(this.pwdList.join(''),'this.pwdList')
	  this.inviteCode = this.pwdList.join('')
	},
	//支付框
	changePwd () {
	  let index = this.pwdList.length
	  index === 6 && index--
	  this.ipt[index].focus()
	},
	//支付框
	changeInput (e) {
		// console.log(e,this.pwdList,'this.pwdList')
	  let index = this.pwdList.length
	  let val = this.pwdList[index - 1]
	　　   if (!/[0-9]/.test(val)) {　
	    this.pwdList.pop() 　　　　  　　　　 
	    return 　　　 　　
	  }
	  if (!val) {
	    this.pwdList.pop()
	    index--
	    if (index > 0) this.ipt[index - 1].focus()
	  } else {
	    if (index < 6) this.ipt[index].focus()
	  }
	},
	// 清空数组
	emptyList(){
		this.pwdList = []
	}
  },
  mounted() {
    // this.cancelFollowed();
	//支付框
	this.ipt = document.querySelectorAll(`#ids_${this.id} .shortInput`)
  },
};
</script>

<style lang="less" scoped>
	
.musician {
  width: 100%;
  background-color: @recommendMusicianListBGColor;
  // height: 92px;
  
}
.musician-head {
  width: 1920px;
  // height: 167px;
  background: @recommendMusicianListBGColor;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.04);
}
.head-content {
  width: 1200px;
  // height: 167px;
  margin: 0 auto;
}
.area {
  display: flex;
  align-items: center;
  margin-top: 24px;
}
.area > p {
  margin-right: 34px;

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222426;
  cursor: pointer;
}
.area > p:nth-child(1) {
  color: #3370ff;
  font-weight: 500;
}
.type {
  display: flex;
  align-items: center;
  margin-top: 24px;
}
.type > p {
  margin-right: 34px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222426;
  cursor: pointer;
}
.type > p:nth-child(1) {
  color: #3370ff;
  font-weight: 500;
}
.musician-intro {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  // padding-left: 16px;
  // padding-right: 16px;
  // padding-top: 10px;
  padding: 16px;
}
.musician-intro > img {
  display: inline-block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  cursor: pointer;
}
.musician-intro-right{
	width: 70%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.tolike {
	  // position: absolute;
	  height: 40px;
	  // margin-top: 18px;
	  // padding-right: 20px;
	  display: flex;
	  // top: 0;
	  // right: 0;
	  cursor: pointer;
	  .create-code{
		  background: @recommendMusicianListBGColor;
		  border-color: #3370FF;
	  }
	  .copy-code{
		  background: #3370FF;
		  color: #fff;
		  border: none;
	  }
	  
	}
}
.musician-content {
  width: 100%;
  margin: 0 auto;
}
.musician-item {
  width: 100%;
  // height: 80px;
  // background: @recommendMusicianListBGColor;
  /* box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.04); */
  border-radius: 4px;
  margin-top: 16px;
  position: relative;
  margin-right: 8px;
}
.intro-brief {
  width: 164px;
  margin-left: 20px;
}
.intro-brief > p {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222426;
  line-height: 22px;
}
.intro-brief > span {
  width: fit-content;
  height: 24px;
  background: #e0f9f6;
  border-radius: 16px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
  line-height: 24px;
  color: #00bea5;
}
.introheng {
  display: inline-block;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #f5f5f5;
  margin-left: 16px;
}

.remainmusician {
  width: 160px;
  height: 40px;
}
.remainmusician:hover {
  background: rgba(51, 112, 255, 0.1);
}
.remainList {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 16px;
}
.remainList > p {
  width: 200px;
  cursor: pointer;
  text-align: center;
  line-height: 40px;
}

.right-button {
	position: absolute;
	height: 100%;
	// margin-top: 18px;
	padding-right: 16px;
	top: 0;
	right: 0;
	cursor: pointer;
	display: flex;
	align-items: center;
	.confirm{
		  width: 146px;
		  height: 84px;
		  background: #3370FF;
		  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.08);
		  border-radius: 4px;
		  display: flex;
		  align-items: center;
		  justify-content: center;
		  font-size: 24px;
		  font-family: PingFangSC-Medium, PingFang SC;
		  font-weight: 500;
		  color: #FFFFFF;
		  line-height: 33px;
		  text-shadow: 0px 0px 6px rgba(0,0,0,0.08);
	}
}
// .tolike >>> .el-button {
//   min-width: 99px;
// }
.tolike > svg {
  display: inline-block;
  width: 48px;
  height: 38px;
}
.moreintro {
  padding-left: 16px;
  padding-right: 16px;
}
.moreintro > span {
  cursor: pointer;
}
.intro-brief .icon {
  width: 78px;
}
.intro-brief p {
  padding-left: 6px;
}
.demotag {
  /* position: absolute; */
  bottom: 12px;
  left: 345px;
  margin: 10px 20px;
  display: flex;
}
.demotag > p {
  height: 24px;
  background: #e0f9f6;
  border-radius: 16px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #00bea5;
  line-height: 24px;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 8px;
  margin-bottom: 0;
}
.musician-intro-left {
  // width: 300px;
  width: 30%;
  height: 100%;
  text-align: center;
  border-right: @recommendMusicianListTextColor 2px dashed;
}
.musician-intro-center-wrap {
	width: 70%;
	display: flex;
	justify-content: space-between;
}
.musician-intro-center {
  // width: 400px;
  height: 60px;
  line-height: 60px;
  color: #00BEA5;
  font-size: 24px;
  text-align: center;
  margin-left: 20px;
}
.musician-intro-center1{
	width: 140px;
	height: 60px;
	line-height: 60px;
	color: #00BEA5;
	font-size: 24px;
	text-align: center;
	margin-left: 20px;
}
.musician-intro-code {
  color: #00BEA5;
  font-size: 20px;
}
.musician-intro-day {
  font-size: 14px;
  color: @recommendMusicianListTextColor;
  margin-top: 8px;
}
 /* 支付框样式 */
    .am_payPwd {
        display: flex;
		align-items: center;
		line-height: 42px;
        // margin-left: 70px;   
        width: 180px;
        height: 42px;
		// border: 1px solid #cccccc;
		// background: #F4F4F4;
		// background: @personInfoFormColor;
		border-radius: 4px;
    
    }
	.am_payPwd_wrap{
		display: flex;
		align-items: center;
		line-height: 42px;
		// margin-left: 70px;   
		// width: 42px;
		height: 42px;
		// background: #F4F4F4;
		// background: @personInfoFormColor;
	}
    /* 支付框样式 */
    .shortInput {
        // background: #cccccc;
		border: 0;
        border-bottom: 1px solid #01B69F;
		margin-right: 10px;
        text-align: center;
        font-size: 40px;
        float: left;
        width: 42px;
        height: 42px;
        color: #01B69F;
        outline: #ff0067;
		background: rgba(0,0,0,0);
		position: relative;
		&::after{
			display:inline-block;
			position: absolute;
			right: 100px;
			top: 100px;
			content: " 1";
			width: 10px;
			height: 20px;
			background: red;
		}
    }
	
	// .shortInput:focus{
	// 	width: 42px;
	// 	height: 42px;
	// 	background: rgba(34,36,38,0.05);
	// 	box-shadow: 0px 0px 6px 0px rgba(34,36,38,0.26);
	// 	border-radius: 4px;
	// 	border: 1px solid #D4D4D4;
	// }
    /* 支付框样式 */
 //    .shortInput:not(:last-child) {
 //        // margin-right: 10px;
 //    }
	// .shortInput:last-child {
	//     border: 0;
	// }
    // .spend-pay-button{
    //     margin: 20px auto 20px 70px;
    // }
       .spend-pay-1{
        // margin-top: 4%;
        position: relative;
    }
    .spend-pay-2{
        font-size: 14px;
        font-weight:550;
        display: inline-block;
        position: absolute;
        top:0;
    }
    .spend-pay-3{
        margin-left: 70px;
        display: inline-block;
        font-size: 10px;
        color: #cccccc; 
    }
    .spend-pay-4{
        margin-left: 85px;
        display: inline-block;
        font-size: 10px;
        font-weight: 600;
    }
</style>